export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/app": [8,[4]],
		"/app/accounts": [9,[4]],
		"/app/accounts/[accountId]": [10,[4]],
		"/app/accounts/[accountId]/transactions": [11,[4]],
		"/app/create-account-transfer": [13,[4]],
		"/app/create-account": [12,[4]],
		"/app/create-category": [14,[4]],
		"/app/create-envelope-transaction": [16,[4]],
		"/app/create-envelope": [15,[4]],
		"/app/create-transaction": [17,[4]],
		"/app/envelopes": [18,[4]],
		"/app/envelopes/[envelopeId]": [19,[4]],
		"/app/envelopes/[envelopeId]/transactions": [20,[4]],
		"/app/intro": [21,[4]],
		"/app/notifications": [22,[4]],
		"/app/settings": [23,[4]],
		"/app/settings/categories": [24,[4]],
		"/app/settings/categories/[categoryId]": [25,[4]],
		"/app/settings/data": [26,[4]],
		"/app/settings/data/import": [27,[4]],
		"/app/settings/plan": [28,[4]],
		"/app/settings/profile": [29,[4]],
		"/app/settings/profile/edit": [30,[4]],
		"/app/update-account-transfer/[accountTransferId]": [32,[4]],
		"/app/update-account/[accountId]": [31,[4]],
		"/app/update-category/[categoryId]": [33,[4]],
		"/app/update-envelope-transaction/[envelopeTransactionId]": [35,[4]],
		"/app/update-envelope/[envelopeId]": [34,[4]],
		"/app/update-transaction/[transactionId]": [36,[4]],
		"/(blog)/blog": [~6,[2],[3]],
		"/(blog)/blog/[slug]": [7,[2],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';